<!--
  PACKAGE_NAME : src\pages\report\bi\wizard-data-table.vue
  FILE_NAME : wizard-data-table
  AUTHOR : kwmoon
  DATE : 2024-06-22
  DESCRIPTION : BI 데이터 시트 추가 모달 내 위자드 보고서 영역
-->
<template>
  <div class="flex flex-row">
    <div class="w-1/6">
      <DxTreeList
        key-expr="id"
        display-expr="menuNm"
        v-model="report.selectedMenuId"
        :data-source="report.list"
        :show-column-headers="true"
        :columns="[{ dataField: 'menuNm', caption: '기본보고서 목록' }]"
        :show-borders="true"
        :auto-expand-all="true"
        :column-auto-width="true"
        :show-column-lines="true"
        :height="500"
        :on-selection-changed="onChangedWizardReportMenu"
      >
        <DxSorting mode="none" />
        <DxSelection mode="single" />
      </DxTreeList>
    </div>
    <div class="w-5/6">
      <ReportSearchBox
        class="pl-10"
        ref="searchBox"
        v-show="report.selectedItem"
        :key="initKey"
        :pageLoaded="pageLoaded"
        :reportInfo="report.info"
        :isWizardReport="false"
      />
      <div class="p-10" v-show="report.selectedItem === null">
        <p>좌측 <b>'기본보고서 목록'</b> 중 하나를 선택하시면,</p>
        <p>보고서를 검색 할 수 있는 <u>컴포넌트가 활성화</u>됩니다.</p>
      </div>
    </div>
  </div>
</template>
<script>
  import { DxSelection, DxTreeList } from 'devextreme-vue/tree-list';
  import { DxSorting } from 'devextreme-vue/data-grid';
  import ReportSearchBox from '@/components/report/search-box';
  import { getResData, isSuccess } from '@/plugins/common-lib';

  export default {
    components: {
      DxTreeList,
      DxSorting,
      DxSelection,
      ReportSearchBox,
    },
    props: {
      wizardReport: {
        type: Object,
        default: () => {
          return {
            list: [],
          };
        },
      },
    },
    watch: {},
    computed: {
      searchBoxInstance() {
        return this.$refs.searchBox;
      },
    },
    data() {
      return {
        initKey: 0,
        pageLoaded: false,
        report: {
          list: [],
          info: {},
          selectedMenuId: null,
          selectedItem: null,
        },
      };
    },
    methods: {
      validateMessageByParams() {
        return this.searchBoxInstance.validateMessageByParams();
      },
      getReportSearchParams() {
        return this.searchBoxInstance.getReportSearchParams();
      },
      getReportInfo() {
        return this.report.info;
      },
      /**
       * 보고서 조회 전 최종적으로 모든 파라미터 업데이트
       * @param {Number} excelFlag 웹(0), 엑셀(1), 일괄(2)
       */
      updateAllReportParam(excelFlag) {
        this.searchBoxInstance.updateAllReportParam(excelFlag);
      },
      /**
       * 보고서 메뉴 트리 리스트에서 아이템 선택(변경) 시
       * @param uReportMenu
       */
      async onChangedWizardReportMenu(e) {
        const rowData = e.selectedRowsData[0];
        this.pageLoaded = false;
        if (rowData.menuDepth === 3) {
          this.initKey++;
          //소메뉴(3)
          this.report.selectedItem = rowData;
          this.report.selectedMenuId = rowData.id;
          const uReportMenu = await this.asyncGetUReportMenuInfo(rowData.id); //메뉴 정보 세팅
          this.report.info = { ...uReportMenu };
          //검색 컴포넌트 초기화 하기 위한 값 변경
          this.pageLoaded = true;
        } else {
          this.report.selectedItem = null;
          this.report.selectedMenuId = null;
        }
      },
      /** @description: 보고서 조회 결과 데이터 가져오기 */
      async asyncGetReportDatas(params) {
        const res = await this.CALL_REPORT_API({
          actionname: 'REPORT_RESULT_LIST',
          data: { data: params },
          loading: true,
        });
        if (isSuccess(res)) return getResData(res);
        //fail
        return [];
      },
      /** @description: 메뉴 ID 값으로 REPORT_MENU 데이터를 추출 요청 API */
      async asyncGetUReportMenuInfo(selectedMenuId) {
        const res = await this.CALL_REPORT_API({
          actionname: 'REPORT_MENU_INFO',
          data: { menuId: selectedMenuId },
          loading: true,
        });
        if (isSuccess(res)) return getResData(res)[0];
      },
      /** @description: 셋팅한 검색 조건 반환하는 메서드 */
      getSearchParams() {
        return this.searchBoxInstance.formData.searchParams;
      },
      /** @description: "데이터 추가" 아이콘 클릭 시 보이는 "위자드 보고서 메뉴" 리스트 셋팅 */
      setWizardReportMenus() {
        const menuList = this.$store.getters.getMenuList
          .filter(d => d.menuTypeCd === 'REPORT' && d.menuDepth > 1)
          .map(item => {
            if (item.menuDepth === 2) {
              return { ...item, parentId: null, icon: 'icon_require' };
            } else {
              return { ...item, icon: 'icon_require' };
            }
          });
        this.report.list = menuList;
      },
    },
    mounted() {
      this.setWizardReportMenus();
    },
    create() {},
    beforeMount() {},
  };
</script>
